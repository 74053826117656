import axios from 'axios';
import {LoadLocalStorage} from "./app/utils/Utilities";

var url = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
    baseURL: url
});


console.log("Connected to: ", url);
console.log("Company: " + (
    LoadLocalStorage('selectedCompany')?.id ?
        `${LoadLocalStorage('selectedCompany')?.id} - ${LoadLocalStorage('selectedCompany')?.name}` :
        "Without selected company")
);

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers['CompanyId'] = LoadLocalStorage('selectedCompany')?.id ?? null;
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error("API Error: ", error);

        if (axios.isCancel(error)) {
            // Handle the case where the request is canceled
            return Promise.reject("Pedido anterior cancelado!");
        }

        // Handle other types of error responses
        const errorMessage = (error.response && error.response.data) || 'Erro ao obter os dados da API!';

        if (error.response && error.response.status === 500) {
            // Handle server errors (status 500)
            return Promise.reject("Erro interno no servidor!");
        }

        return Promise.reject(errorMessage);
    }
);

/*
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {

        console.error(error);

        switch (error?.code ?? null) {
            case "ERR_CANCELED":
                return Promise.reject("Pedido anterior cancelado!");
            default:
                return Promise.reject((error.response && error.response.data) || 'Erro a obter os dados da API!');
        }


    })*/

export default axiosInstance;
