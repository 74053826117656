import { IconButton, Card, Icon, Tooltip } from '@mui/material';
import { Box, styled } from '@mui/system';
import {useEffect, useState} from "react";

const CardRoot = styled(Card)(() => ({
    height: '100%',
    padding: '5px 0px',
    borderRadius: '12px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
}));

const MenuCard = ({ children, level = 0}) => {
    const [backgroundColor, setBackgroundColor] = useState("#FFFFF")

    useEffect(() => {
        switch (level) {
            case 0:
                setBackgroundColor("#FFFFF")
                break;
            case 1:
                setBackgroundColor("#F5F5F5")
                break;
            case 2:
                setBackgroundColor("#E8E8E8")
                break;
            case 3:
                setBackgroundColor("#D9D9D9")
                break;
            case 4:
                setBackgroundColor("#D9D9D9")
                break;
            default:
                setBackgroundColor("#FFFFF")
        }
    }, [level]);


    return (
        <CardRoot elevation={3} style={{ backgroundColor: backgroundColor }}>
            {children}
        </CardRoot>
    );
};

export default MenuCard;
