import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';


const ListPage = Loadable(lazy(() => import('./ListPage')));
const New = Loadable(lazy(() => import('./New')));
const ChargeEntity = Loadable(lazy(() => import('./ChargeEntity')));
const EntityContacts = Loadable(lazy(() => import('./EntityContacts')));

const entityRoutes = [
    { path: '/auth/entity/list', element: <ListPage />, auth: [authRoles.sa] },
    { path: '/auth/entity/add', element: <New/>, auth: [authRoles.sa] },
    { path: '/auth/entity/contacts', element: <EntityContacts/>, auth: [authRoles.sa] },
    { path: '/auth/entity/charge/file', element: <ChargeEntity />, auth: [authRoles.sa] },
    
];


export default entityRoutes;
