import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Main = Loadable(lazy(() => import('./Main')));



const iFrameRoutes = [
    { path: '/auth/iframe', element: <Main />, auth: [authRoles.sa] },

];

export default iFrameRoutes;
