import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';


const ProductionPage = Loadable(lazy(() => import('./ProductionPage')));


const ProductionRoutes = [
    { path: '/auth/tfp/management/production', element: <ProductionPage />, auth: [authRoles.sa] },
    
    
];

export default ProductionRoutes;
