import React, {createContext, useEffect, useReducer} from 'react'
import axios from 'axios.js'
import useAuth from '../hooks/useAuth';
import {SaveLocalStorage, LoadLocalStorage} from '../utils/Utilities'
import useSignalR from '../hooks/useSignalR';
import useSettings from '../hooks/useSettings';
import {MatxLayoutSettings} from '../components/MatxLayout/settings';

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_COMPANYS': {
            return {
                ...state,
                companies: action.payload,
            }
        }
        case 'CLEAR_COMPANYS': {
            return {
                ...state,
                companies: action.payload,
            }
        }
        case 'SELECTED_COMPANY': {
            return {
                ...state,
                selectedCompany: action.payload,
            }
        }

        default: {
            return {...state}
        }
    }
}

const CompanyContext = createContext({
    companies: null,
    selectedCompany: null,

    clearCompanies: () => {
    },
    getCompanies: () => Promise.resolve(),
    getSelectedCompany: () => {
    },
    postSelectCompany: () => Promise.resolve()

})

export const CompanyProvider = ({settings, children}) => {
    const [state, dispatch] = useReducer(reducer, []);
    const {user} = useAuth();
    const {updateSettings} = useSettings();

    const {connectionHub} = useSignalR();


    const loadSession = () => {
        var accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            axios.defaults.baseURL = (process.env.REACT_APP_API_URL);   //process.env.REACT_APP_API_URL_DEVMODE;
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        } else {
            delete axios.defaults.headers.common.Authorization
        }
    }
    loadSession();


    const clearCompanies = async () => {
        try {
            const res = await axios.post('/Notification/delete-all')
            dispatch({
                type: 'CLEAR_COMPANYS',
                payload: res.data,
            })
        } catch (e) {
            console.error(e)
        }
    }


    const getCompanies = async () => {
        try {
            const res = await axios.get('/Company/GetCompanies')
            dispatch({
                type: 'LOAD_COMPANYS',
                payload: res.data,
            });
            return res.data;
        } catch (e) {
            console.error(e)
        }
    }

    const getSelectedCompany = () => {
        try {
            var aux = LoadLocalStorage('selectedCompany');
            return aux || null;
        } catch (e) {
            console.error(e)
            return null;
        }
    }

    const setSelectedCompany = async (selectedCompany) => {
        if (selectedCompany !== null) {
            SaveLocalStorage('selectedCompany', null);
            SaveLocalStorage('selectedCompany', selectedCompany);
        } else {
            SaveLocalStorage('selectedCompany', null);
        }
    }
    const postSelectCompany = async (company) => {
        try {

            if (company === null) {
                await RevokeAccessUser();
                connectionHub?.send("SendMessageToGroup", LoadLocalStorage('selectedCompany')?.id?.toString(), user?.firstName + " conectou-se á empresa.");
            }
            const selectedCompanyId = +LoadLocalStorage('selectedCompany')?.id;
            const companyId = +company?.id;

            if (selectedCompanyId !== companyId) {
                //await RevokeAccessUser(LoadLocalStorage('selectedCompany'));
                connectionHub?.send("SendMessageToGroup", LoadLocalStorage('selectedCompany')?.id?.toString(), user?.firstName + " desconectou-se da empresa.");
            }
            dispatch({type: 'SELECTED_COMPANY', payload: company});
            setSelectedCompany(company || null);

            if (connectionHub && connectionHub?.state === 'Connected' && company?.id !== LoadLocalStorage('selectedCompany')?.id) {
                connectionHub?.send("ReJoinGroup", company?.id?.toString());
                connectionHub?.send("SendMessageToGroup", company?.id?.toString(), user?.firstName + " conectou-se á empresa.");
            }

            axios.defaults.headers.common['CompanyId'] = company?.id ?? null;

            //CLEAR DATA FROM CACHE FILTERS
            SaveLocalStorage('accountFilter', null);
            const responseRole = await GetUserPageRoles(company);
            SaveLocalStorage("accessRoles", responseRole);
        } catch (e) {
            console.error(e)
        }
    }

    const GetUserPageRoles = async (company) => {
        try {
            var response = await axios.get('/ConfigPage/GetUserPageRoles', {params: {companyId: company?.id ?? null}});
            return response?.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    const RevokeAccessUser = async (company) => {
        try {
            var response = await axios.post('/LoggedUser/RevokeAccessUser', {companyId: company?.id});
            return response?.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }


    useEffect(() => {
        (async () => {
            if (user) {
                await getCompanies();
            }
        })()
    }, [user]);


    useEffect(() => {
        (async () => {
            try {
                //console.log("COMPANYCONTECT")
                if (user) {
                    const companies = state?.companies || [];
                    const decryptedSelectedCompany = LoadLocalStorage('selectedCompany') || null;
                    if (companies.length !== 0) {
                        if (decryptedSelectedCompany !== null && companies.some(item => item?.id === decryptedSelectedCompany?.id)) {
                            await postSelectCompany(decryptedSelectedCompany);
                        } else if (!user?.accountRoles.some(item => item.role === "SA") && companies.length === 1) {
                            var company = companies[0];
                            await postSelectCompany(company);
                            updateSettings(company?.themeSettings !== undefined ? JSON.parse(company?.themeSettings) : MatxLayoutSettings);

                        } else {
                            await postSelectCompany(null);
                        }
                    } else {
                        await postSelectCompany(decryptedSelectedCompany);

                    }
                }
            } catch (err) {
                console.error(err);
                await postSelectCompany(null);
            }
        })();
    }, [state?.companies, user]);


    return (

        <CompanyContext.Provider
            value={{
                companies: state?.companies || [],
                selectedCompany: state?.selectedCompany || null,
                clearCompanies,
                getCompanies,
                postSelectCompany,
                getSelectedCompany

            }}
        >
            {children}
        </CompanyContext.Provider>
    )
}

export default CompanyContext;
