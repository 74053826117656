import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';


const SchedularPage = Loadable(lazy(() => import('./SchedularPage')));


const schedularRoutes = [
    { path: '/auth/tfp/management/schedular', element: <SchedularPage />, auth: [authRoles.sa] },
    
    
];

export default schedularRoutes;
